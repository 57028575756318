import {createRouter, createWebHistory} from 'vue-router';
import SlideShow from '../components/SlideShow.vue';

const routes = [
    {
        path: '/:alias',
        name: 'SlideShow',
        component: SlideShow
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;