<template>
  <div>
    <div v-if="slides.length > 0">
      <img :src="currentSlide.imageURL" alt="Slide" :key="currentSlide.id"/>
    </div>
    <div v-else>
      {{ placeholder }}
    </div>
    <div style="display: none;">
      <img v-for="slide in slides" :src="slide.imageURL" :key="slide.id"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {onMounted, ref, watch} from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'SlideShow',
  setup() {
    const route = useRoute();
    const slides = ref([]);
    const currentIndex = ref(0);
    const currentSlide = ref({});
    let placeholder = ref("Загрузка плейлиста...");

    const fetchSlides = async () => {
      try {
        const alias = route.params.alias;
        const apiUrl = process.env.VUE_APP_API_URL;
        const response = await axios.get(`${apiUrl}/public/v1/tv/${alias}/playlist`);
        slides.value = response.data.body.slides;
        if (slides.value.length > 0) {
          currentSlide.value = slides.value[0];
          startSlideShow();
        }
      } catch (error) {
        console.error("Ошибка при загрузке плейлиста:", error);
        placeholder.value = "Плейлист не найден " + error.message
      }
    };

    const startSlideShow = () => {
      watch(currentIndex, () => {
        if (slides.value.length > 0) {
          currentSlide.value = slides.value[currentIndex.value];
          setTimeout(() => {
            currentIndex.value = (currentIndex.value + 1) % slides.value.length;
          }, currentSlide.value.delaySeconds * 1000);
        }
      }, {immediate: true});
    };

    onMounted(() => {
      fetchSlides();
    });

    return {
      slides,
      currentSlide,
      placeholder
    };
  }
};
</script>

<style>
img {
  max-width: 100%;
  height: auto;
}
</style>
