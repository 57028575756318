<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0px;
}
</style>